import * as React from 'react'
import Head from 'next/head'
import NotFound from 'pages_/NotFound'
import { useTranslations } from 'containers/Translations/TranslationsContext'

function NotFoundPage() {
  const t = useTranslations()
  return (
    <>
      <Head>
        <title>{t('Web.PageNotFound.Title')}</title>
      </Head>

      <NotFound />
    </>
  )
}

export default NotFoundPage
