import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'
import { useGlobal } from 'api'
import RouterLink from 'containers/RouterLink'
import Container from 'components/Container'
import Link from 'components/Link'
import Section from 'components/Section'
import Typography from 'components/Typography'
import { useTranslations } from 'containers/Translations/TranslationsContext'

export const useStyles = makeStyles((theme) => ({
  section: {
    padding: 'calc(var(--coa-section-spacing) * 2) 0',
  },
  container: theme.mixins.contain(500),
  notFound: {
    textAlign: 'center',
  },
  heading: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  mediaContainer: {
    aspectRatio: '1/1',
    borderRadius: '100%',
    overflow: 'hidden',
  },
  media: {
    display: 'block',
    width: '100%',
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.divider,
  },
  list: theme.typography.body1,
}))

function NotFound(props) {
  const { expiredToken } = props
  const classes = useStyles(props)

  const { images } = useGlobal()
  const t = useTranslations()

  return (
    <>
      <Section className={classes.section}>
        <Container className={classes.container}>
          <div className={classes.notFound}>
            <Typography className={classes.heading} component="h1" variant="h3">
              {t('Web.NotFound.SomethingWentWrong')}
            </Typography>
            <Typography>
              {expiredToken ? t('Web.DirectPayment.LinkExpired') : t('Web.NotFound.PageNotFound')}
              {` `}
              <Link component={RouterLink} href="/" underline="always">
                {t('Web.NotFound.HomePage')}
              </Link>
              .
            </Typography>
          </div>
        </Container>
      </Section>

      <div className={classes.divider} />

      <Section className={classes.section}>
        <Container className={classes.container}>
          <div className={classes.mediaContainer}>
            <img className={classes.media} src={images.notFound} alt="Not found" />
          </div>

          <br />

          <Typography align="center" component="p" variant="h6" paragraph>
            {t('Web.NotFound.RelaxingTitle')}
          </Typography>

          <Typography align="center" component="p" variant="h2" paragraph>
            {t('Web.NotFound.RelaxingDescription')}
          </Typography>
        </Container>
      </Section>
    </>
  )
}

NotFound.propTypes = {
  expiredToken: PropTypes.object,
}

export default NotFound
